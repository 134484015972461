export function populateAtlasLegend(nominalDisplayType) {
    let count = $j('.gribLegendNumbers').each((i, div) => {
        $j(div).empty();
        const displayType = $j(div).closest('.gribLegend').attr('data-display-type');
        if (nominalDisplayType) console.assert(nominalDisplayType === displayType);
        let numbers = [];
        // The numbers array should start with a min value, end with a max, and in between contain the labels to be shown
        // (i.e. min and max are NOT displayed unless they are repeated.)
        const userUnits = $j('.gribLegend').data('units');
        let legendType = (displayType === 'Gust') ? 'Wind' : displayType;
        let unit = userUnits[legendType]; // Note: smartphone and website use varying unit names :(
        if (unit) {
            legendType += '-' + unit;
        }
        console.log("legendType: '" + legendType + "'");
        switch (legendType) {
            case 'Wind-Knots':
            case 'Gust':
                numbers = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 50];
                unit = pgettext('unit', 'Knots');
                break;
            case 'Wind-m/s':
            case 'Wind-Meters/Second':
                numbers = [0, 5, 10, 15, 20, 25, 25.72];
                unit = pgettext('unit', "m/s");
                break;
            case 'Wind-mph':
            case 'Wind-Miles per Hour':
                numbers = [0, 10, 20, 30, 40, 50, 57.54];
                unit = pgettext('unit', 'MPH');
                break;
            case 'Wind-kph':
            case 'Wind-Kilometers per Hour':
                numbers = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 92.6];
                unit = pgettext('unit', 'km/h');
                break;
            case 'Current':
                // Linear colour scale represents sqrt(speed/6 knots), range 0-1.
                numbers = [0,
                    {label: 1, val: Math.sqrt(1 / 6)},
                    {label: 2, val: Math.sqrt(2 / 6)},
                    {label: 3, val: Math.sqrt(3 / 6)},
                    {label: 4, val: Math.sqrt(4 / 6)},
                    {label: 5, val: Math.sqrt(5 / 6)},
                    {label: 6, val: Math.sqrt(6 / 6)},
                    1];
                unit = pgettext('unit', 'Knots');
                break;
            case 'Wind-Beaufort':
                // Beaufort labels with values in knots for positioning
                numbers = [0,
                    {label: 1, val: 1.6251},
                    {label: 2, val: 4.5965},
                    {label: 3, val: 8.4443},
                    {label: 4, val: 13.0008},
                    {label: 5, val: 18.1692},
                    {label: 6, val: 23.8840},
                    {label: 7, val: 30.0973},
                    {label: 8, val: 36.7718},
                    {label: 9, val: 43.8777},
                    50];
                unit = pgettext('unit', 'Beaufort');
                break;
            case 'Rain-mm':
            case 'Rain-mm/hr':
            case 'Isobar-mm':
            case 'Isobar-mm/hr':
                numbers = [0,
                    {label: 0.3, val: 1},
                    {label: 1.0, val: 2},
                    {label: 2.5, val: 3},
                    {label: 5.0, val: 4},
                    {label: 10, val: 5},
                    {label: 25, val: 6},
                    6];
                unit = pgettext('unit', "mm/hr");
                break;
            case 'Rain-inches':
            case 'Rain-inches/hr':
            case 'Isobar-inches':
            case 'Isobar-inches/hr':
                numbers = [0,
                    {label: 0.02, val: 1},
                    {label: 0.05, val: 2},
                    {label: 0.1, val: 3},
                    {label: 0.25, val: 4},
                    {label: 0.5, val: 5},
                    {label: 1.0, val: 6},
                    6];
                unit = pgettext('unit', "inches/hr");
                break;
            case 'Cloud':
                numbers = [0, 50, 100, 100];
                unit = "%";
                break;
            case 'AirTemp-Celsius':
                numbers = [-3, 0, 3, 6, 9, 12, 15, 18, 21, 24, 27, 30, 30];
                unit = pgettext('unit', "&deg;C");
                break;
            case 'AirTemp-Fahrenheit':
            case 'AirTemp-Farenheit': // unit is spelt wrong in python code :(
                numbers = [26.6, 30, 40, 50, 60, 70, 80, 86];
                unit = pgettext('unit', "&deg;F");
                break;
            case 'SeaTemp':
            case 'SeaTemp-Celsius':
                numbers = [12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 30];
                unit = pgettext('unit', "&deg;C");
                break;
            case 'SeaTemp-Fahrenheit':
            case 'SeaTemp-Farenheit':
                numbers = [53.6, 55, 60, 65, 70, 75, 80, 85, 86.0];
                unit = pgettext('unit', "&deg;F");
                break;
            case 'Wave-Feet':
            case 'Wave-ft':
                numbers = [0, 5, 10, 15, 20, 25, 30, 32.8084];
                unit = pgettext('unit', "Feet");
                break;
            case "Wave-m":
            case 'Wave-Meters':
                numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 10];
                unit = pgettext('unit', "m");
                break;
            case 'CAPE':
                numbers = [0, 1000, 2000, 3500, 3500];
                unit = pgettext('unit', 'J/kg');
        }
        const min = numbers.shift(), max = numbers.pop(), range = max - min;
        numbers.forEach(function (num) {
            let label, val;
            if (typeof num === "object") {
                label = num["label"];
                val = num["val"];
            } else {
                val = label = num;
            }

            $j('<div>')
                .css('position', 'absolute').css('left', (100 * (val - min) / range) + "%")
                .css('width', '50px').css('margin-left', '-25px')
                .css('text-align', 'center').text(label)
                .appendTo(div);
        });
        $j('<span>').html(unit).appendTo(div).addClass(unit !== '' ? "gribLegendUnit" : "gribLegendNoUnit");
    }).length;
    console.log(`Updated ${count} legend(s)`);
}


